import React, { useState } from "react";
import HeaderBanner from "../Shared/Header-Banner";

import httpService from "../../Services/http-services";
import upd from "../../assets/image/upd.png";
import contactImage from "../../assets/image/contact.png";

const Contact = () => {
  const initialFormState = {
    name: "",
    email: "",
    contact: "",
    company: "",
    message: "",
  };

  const [formData, setFormData] = useState(initialFormState);

  const { name, email, contact, company, message } = formData;

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    // const values = Object.values(formData);
    e.preventDefault();
    try {
      const response = await httpService.post("/webformquery", formData);
      handleReset();
    } catch (error) {
      console.log(error);
    }
  };

  const handleReset = () => {
    setFormData(initialFormState);
  };

  return (
    <div>
      <section>
        <HeaderBanner />
      </section>
      <section className="contact-page section">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="social-side">
                <div className="chat">
                  <h5>
                    <span>
                      <i className="fa-regular fa-comments"></i>
                    </span>
                    Chat
                  </h5>
                  <a>
                    sales@trebta.com
                    <span>
                      <img src={upd} alt="" title="" />
                    </span>
                  </a>
                </div>
                <div className="chat">
                  <h5>
                    <span>
                      <i className="fa-solid fa-phone-volume"></i>
                    </span>
                    Call
                  </h5>
                  <a>
                    +91-971-694-7080
                    <span>
                      <img src={upd} alt="" title="" />
                    </span>
                  </a>
                  <a>
                    +91-935-410-9981
                    <span>
                      <img src={upd} alt="" title="" />
                    </span>
                  </a>
                </div>
                <div className="chat">
                  <h5>
                    <span>
                      <i className="fa-solid fa-location-dot"></i>
                    </span>
                    Visit us
                  </h5>
                  <a>
                    Plot no - D3, Noida - 201301,
                    <br /> Uttar Pradesh
                    <span>
                      <img src={upd} alt="" title="" />
                    </span>
                  </a>
                </div>
              </div>
              <div className="image-co">
                <img src={contactImage} alt="" title="" />
              </div>
            </div>
            <div className="col-md-7">
              <div className="inner-form">
                <div className="out-side ">
                  <h2>Your Ideas. Our Skills. Lets team Up.</h2>
                  {/* <p>Let us create something big.</p> */}
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label htmlFor="name">Name:</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Your full Name"
                      id="name"
                      name="name"
                      value={name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email:</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Your E-mail"
                      id="email"
                      name="email"
                      value={email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="contact">Contact:</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Your Contact"
                      id="contact"
                      name="contact"
                      value={contact}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="company">Company(Optional):</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Your Company (Optional)"
                      id="company"
                      name="company"
                      value={company}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="message">Message:</label>
                    <textarea
                      className="form-control"
                      placeholder="Tell us a little about your ideas"
                      id="message"
                      name="message"
                      value={message}
                      onChange={handleChange}
                      required
                    ></textarea>
                  </div>
                  <button
                    type="submit"
                    className="primary-button mt-3 text-uppercase"
                  >
                    speak with a software expert
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact;
