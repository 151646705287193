import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/image/logo.png';

const Header = () => {
  const menuOverlayRef = useRef(null);
  const mLinksRef = useRef(null);
  const menuOpenBtnRef = useRef(null);
  const menuCloseBtnRef = useRef(null);

  const openMenu = () => {
    if (menuOverlayRef.current) menuOverlayRef.current.classList.add('active');
    if (mLinksRef.current) mLinksRef.current.style.left = '0';
  };

  const closeMenu = () => {
    if (menuOverlayRef.current) menuOverlayRef.current.classList.remove('active');
    if (mLinksRef.current) mLinksRef.current.style.left = '-100%';
  };

  const handleLinkClick = () => {
    closeMenu();
  };

  React.useEffect(() => {
    // Set up event listeners
    if (menuOpenBtnRef.current) menuOpenBtnRef.current.addEventListener('click', openMenu);
    if (menuCloseBtnRef.current) menuCloseBtnRef.current.addEventListener('click', closeMenu);
    if (menuOverlayRef.current) menuOverlayRef.current.addEventListener('click', closeMenu);

    // Clean up event listeners
    return () => {
      if (menuOpenBtnRef.current) menuOpenBtnRef.current.removeEventListener('click', openMenu);
      if (menuCloseBtnRef.current) menuCloseBtnRef.current.removeEventListener('click', closeMenu);
      if (menuOverlayRef.current) menuOverlayRef.current.removeEventListener('click', closeMenu);
    };
  }, []);

  return (
    <header>
      <div className="header container">
        <div className="menu-overlay" ref={menuOverlayRef}></div>
        <i className="fa-sharp fa-solid fa-bars" ref={menuOpenBtnRef}></i>
        <div className="logo">
          <Link to="/">
            <img src={logo} width="100%" title="TREBTA" alt="TREBTA" height="100%" />
          </Link>
        </div>
        <div className="m_links" ref={mLinksRef}>
          <div className="sidebar-logo">
            <span className="logo-name">
              <Link to="/">
                <img src={logo} width="100%" title="TREBTA" alt="TREBTA" height="100%" />
              </Link>
            </span>
            <i className="fa-solid fa-xmark" ref={menuCloseBtnRef}></i>
          </div>
          <div>
            <ul className="links">
              <li><Link to="/services" onClick={handleLinkClick}>Services</Link></li>
              <li><Link to="/portfolio" onClick={handleLinkClick}>Portfolio</Link></li>
              <li><Link to="/about" onClick={handleLinkClick}>About Us</Link></li>
              <li><Link to="/faq" onClick={handleLinkClick}>FAQs</Link></li>
              <div className="button mobile-button">
                <div className="button-group">
                  <Link style={{ textDecoration: 'none' }} to="/contact" className="primary-button" onClick={handleLinkClick}>Turn Ideas into Reality</Link>
                </div>
              </div>
            </ul>
          </div>
        </div>
        <div className="button">
          <div className="button-group">
            <Link style={{ textDecoration: 'none' }} to="/contact" className="primary-button">Turn Ideas into Reality</Link>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;