import React from 'react';
import { Link } from 'react-router-dom';

import HeaderBanner from '../Shared/Header-Banner';

const About = () => {
  return (
    <div>
      <HeaderBanner />
      <section className="about-section sectiontop">
        <div className="container">
          <div className="co-12">
            <div className="inner-box">
              <div className="heading-border">
                <h2>About Us</h2>
              </div>
              <p>At Trebta, we are passionate about harnessing the power of technology to shape the future. As an emerging player in the IT industry, we are dedicated to revolutionizing the way businesses operate, connect, and grow in the digital era. Through cutting-edge software solutions, we strive to empower organizations with the tools they need to thrive in a rapidly evolving world.</p>
            </div>
          </div>
          <div className="co-12">
            <div className="inner-box">
              <div className="heading-border">
                <h2>Our Vision</h2>
              </div>
              <p>At Trebta, our vision is to be a leading force in the software development landscape, setting new standards for excellence and innovation. We envision a future where businesses can leverage technology seamlessly and effortlessly to achieve their goals. By staying at the forefront of emerging technologies and embracing a culture of continuous learning, we aim to be the go-to partner for organizations seeking forward-thinking software solutions.</p>
            </div>
          </div>
          <div className="co-12">
            <div className="inner-box">
              <div className="heading-border">
                <h2>Our Mission</h2>
              </div>
              <p>Our mission is simple yet powerful: to create innovative software solutions that drive tangible results for our clients. We believe that technology should be a catalyst for positive change, and our mission is to harness its potential to transform businesses, industries, and lives. With a customer-centric approach, we aim to deliver exceptional software products that exceed expectations, drive efficiency, and unlock new opportunities.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="cta-seciton p-4 mt-5 " style={{ backgroundColor: '#0144B9' }}>
        <div className="container">
          <div className="inner-box d-flex gap-4 align-items-center flex-wrap" style={{ justifyContentt: 'space-between' }}>
            <div className="heading">
              <h2 className="text-white">Request for a free consultation</h2>
            </div>
            <div className="button-text">
              <Link to="/contact" className="primary-button text-white border-white">GET IN TOUCH</Link>
            </div>
          </div>
        </div>
      </section>
      <section className="why_work section">
        <div className="container">
          <div className="heading-border">
            <h2>Why Work with Us</h2>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="inner-heading">
                <div className="heading-border border-left d-inline-block">
                  <h3>Expertise and Excellence</h3>
                </div>
                <div className="innner-box">
                  <p>Our team comprises highly skilled professionals who are experts in their respective fields. From talented developers and designers to visionary strategists, we bring together a diverse range of expertise to create exceptional software solutions tailored to your specific needs.</p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="inner-heading ">
                <div className="heading-border border-left d-inline-block">
                  <h3>Client-Centric Approach</h3>
                </div>
                <div className="innner-box">
                  <p>We believe that strong relationships are built on trust, collaboration, and mutual success. We listen attentively to our clients, understanding their unique challenges and goals, and work closely with them throughout the development process. Your satisfaction and success are our top priorities.</p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="inner-heading">
                <div className="heading-border border-left d-inline-block">
                  <h3>Timely Delivery & Support</h3>
                </div>
                <div className="innner-box">
                  <p>We are committed to delivering projects on time, without compromising on quality. We understand the importance of meeting deadlines and ensuring smooth project execution. Even after the delivery, we provide ongoing support and maintenance to ensure your software continues to perform at its best.</p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="inner-heading">
                <div className="heading-border border-left d-inline-block">
                  <h3>Customized Solutions</h3>
                </div>
                <div className="innner-box">
                  <p>We understand that every business is unique, with its own set of requirements and goals. That's why we take a personalized approach to software development, tailoring our solutions to fit your specific needs. Whether you need a robust web application, a user-friendly mobile app, or a scalable enterprise software, we've got you covered.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="inner-heading">
                <div className="heading-border border-left d-inline-block">
                  <h3>Innovation at the Core</h3>
                </div>
                <div className="innner-box">
                  <p>Innovation is at the heart of everything we do. We embrace emerging technologies and leverage the latest trends to create software that drives growth, efficiency, and digital transformation. By partnering with us, you gain access to groundbreaking solutions that give you a competitive edge.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="inner-heading">
                <div className="heading-border border-left d-inline-block">
                  <h3>Work Together</h3>
                </div>
                <div className="innner-box">
                  <p>Join us on our journey to shape the future through technology. Together, let's unlock endless possibilities and drive digital transformation. Contact us today to discover how our software solutions can empower your business to thrive in the digital age.
                  </p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>
    </div>
  )
}

export default About;