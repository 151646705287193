import React from 'react';
import { Link } from 'react-router-dom';

import meeting from '../../assets/image/meeting.png';
import process from '../../assets/image/process.svg';
import vector from '../../assets/image/Vector.svg';
import banner from '../../assets/image/banner.svg';

const Home = () => {
  return (
    <div>
      <section className="hero_seciton">
        <div className="shape">
          <div>
            <img src={vector} width="100%" height="100%" title="" alt="" />
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="inner-box">
                <h1>We build apps and <span className="primary-color">solutions.</span></h1>
                <div className="button-group">
                  <Link style={{ textDecoration: 'none' }} to="contact" className="primary-button secondary-button">Talk to our Experts</Link>
                  <Link style={{ textDecoration: 'none' }} to="services" className="primary-button ">Services</Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="inner-img">
                <img src={banner} width="100%" height="100%" alt="" title="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="what_we_do section">
        <div className="container">
          <div className="heading-border">
            <h2>What we do</h2>
          </div>
          <div className="row">
            <div className="inner-box">
              <ul>
                <li>
                  Web Development
                </li>
                <li>
                  UI & UX Design
                </li>
                <li>
                  eCommerce Websites
                </li>
                <li>
                  Devops
                </li>
                <li>
                  Digital Marketing
                </li>
                <li>
                  Wordpress
                </li>
                <li>
                  Testing & QA
                </li>
                <li>
                  MVPs & Prototypes
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="button-section sectionbottom">
        <Link style={{ textDecoration: 'none' }} to="services" className="primary-button">Read more about our services</Link>
      </section>
      <section className="Schedule " style={{
        backgroundImage: `url(${meeting})`,
      }}>
        <div className="container">
          <div className="col-lg-5 col-md-8">
            <h2>Schedule an initial 30-min call</h2>
            <p>Lets discuss your ideas. We will explain how we can help. With no obligations.</p>
            <div className="button-container">
              <input type="email" placeholder="Your Email" />
              <a style={{ textDecoration: 'none' }} href="#" className="secondary-button primary-button">Get a call back</a>
            </div>
          </div>
        </div>
      </section>
      <section className="our-process section">
        <div className="container">
          <div className="heading-border">
            <h2>Dive into our process</h2>
          </div>
          <div className="row">
            <div className="'col-md-12">
              <div className="inner-box">
                <img src={process} width="100%" height="100%" alt="Dive into our process"
                  title="Dive into our process" loading="lazy" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
};

export default Home;