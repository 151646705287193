import React from 'react';

import HeaderBanner from '../Shared/Header-Banner';

import mobileapp from '../../assets/image/mobile-app.svg';
import website from '../../assets/image/website.svg';
import buyonline from '../../assets/image/buy-online.svg';
import communicate from '../../assets/image/communicate.svg';
import designTools from '../../assets/image/design-tools.svg';
import servers from '../../assets/image/servers.svg';

const Services = () => {
  return (
    <div>
      <HeaderBanner />
      <section className=" services-section mt-5" id="service">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="sec-heading text-center pera-block">
                <h2>
                  See what we can <span className="ree-text rt40">do</span> for{" "}
                  <span className="ree-text rt40">you</span>
                </h2>
                <p>
                  Experience the elegance of cutting-edge software solutions with Trebta. We tailor sophistication to your specific needs, enabling your business to thrive in the digital age.
                  Elevate your business with our refined software services at Trebta. Embrace innovation and elegance as we deliver seamless solutions for remarkable growth.
                  Discover the perfect blend of creativity and expertise with Trebta's software offerings. Unlock your business's full potential with our personalized and elegant solutions.
                </p>
              </div>
            </div>
          </div>
          <div className="row mt30">
            <div className="col-lg-4 col-sm-6 ">
              <div className="services-inner-box f7f7-color mt60">
                <div className="services-inner-box-img shadows">
                  <img src={mobileapp} alt="services" />{" "}
                </div>
                <div className="services-inner-box-content mt40">
                  <h3 className="mb15">
                    <a href="#"> App Development</a>
                  </h3>
                  <p>
                    Our software house has been recognised by google for outstanding
                    android application quality.
                  </p>
                </div>
                <div className="services-inner-box-link">
                  <a style={{ textDecoration: 'none' }} href="#" className="links-card mt40">
                    Read More <i className="fas fa-arrow-right fa-btn" />{" "}
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 ">
              <div className="services-inner-box f7f7-color  mt60">
                <div className="services-inner-box-img">
                  <img src={website} alt="services" />{" "}
                </div>
                <div className="services-inner-box-content mt40">
                  <h3 className="mb15">
                    <a href="#">Web Development</a>
                  </h3>
                  <p>
                    At Trebta, We offer pixel perfect responsive website design
                    services which are built around aesthetics and usability.
                  </p>
                </div>
                <div className="services-inner-box-link">
                  <a style={{ textDecoration: 'none' }} href="#" className="links-card mt40">
                    Read More <i className="fas fa-arrow-right fa-btn" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 ">
              <div className="services-inner-box f7f7-color  mt60">
                <div className="services-inner-box-img">
                  <img src={buyonline} alt="services" />{" "}
                </div>
                <div className="services-inner-box-content mt40">
                  <h3 className="mb15">
                    <a href="#">eCommerce Service</a>
                  </h3>
                  <p>
                    Trebta’s product design services cover entire product design
                    lifecycle, From prototyping to fully functional design.
                  </p>
                </div>
                <div className="services-inner-box-link">
                  <a style={{ textDecoration: 'none' }} href="#" className="links-card mt40">
                    Read More <i className="fas fa-arrow-right fa-btn" />
                  </a>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-sm-6 "
              data-aos="fade-up"
              data-aos-delay={700}
            >
              <div className="services-inner-box f7f7-color  mt60">
                <div className="services-inner-box-img">
                  <img src={communicate} alt="services" />{" "}
                </div>
                <div className="services-inner-box-content mt40">
                  <h3 className="mb15">
                    <a href="#">Digital Marketing</a>
                  </h3>
                  <p>
                    Increase conversions. Increase leads. Increase sales. Increase
                    revenue. That's digital marketing services from Trebta.
                  </p>
                </div>
                <div className="services-inner-box-link">
                  <a style={{ textDecoration: 'none' }} href="#" className="links-card mt40">
                    Read More <i className="fas fa-arrow-right fa-btn" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 ">
              <div className="services-inner-box f7f7-color  mt60">
                <div className="services-inner-box-img">
                  <img src={designTools} alt="services" />{" "}
                </div>
                <div className="services-inner-box-content mt40">
                  <h3 className="mb15">
                    <a href="#">Product Design</a>
                  </h3>
                  <p>
                    Our highly experienced designers comprehend your vision and
                    business objectives in order to create the designs.
                  </p>
                </div>
                <div className="services-inner-box-link">
                  <a style={{ textDecoration: 'none' }} href="#" className="links-card mt40">
                    Read More <i className="fas fa-arrow-right fa-btn" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 ">
              <div className="services-inner-box f7f7-color  mt60">
                <div className="services-inner-box-img">
                  <img src={servers} alt="services" />{" "}
                </div>
                <div className="services-inner-box-content mt40">
                  <h3 className="mb15">
                    <a href="#">Cloud Services</a>
                  </h3>
                  <p>
                    Our highly experienced designers comprehend your vision and
                    business objectives in order to create the designs.
                  </p>
                </div>
                <div className="services-inner-box-link">
                  <a style={{ textDecoration: 'none' }} href="#" className="links-card mt40">
                    Read More <i className="fas fa-arrow-right fa-btn" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="cta-block-wide mt100">
            <div className="row justify-content-center text-center">
              <div className="col-lg-10 vcenter">
                <div className="cta-heading-wide-bt mt-5">
                  <h3>Hire World-Class Developers</h3>
                  <a style={{ textDecoration: 'none' }} href="#" className="primary-button mt-4">
                    Hire Now <i className="fas fa-arrow-right fa-btn" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </section>
    </div>
  )
};

export default Services;