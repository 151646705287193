import axios from 'axios';

const httpService = axios.create({
  baseURL: 'https://localhost:7007/api/', // Replace with your API base URL
  timeout: 5000, // Adjust the timeout value as per your requirements
});

// Request interceptor
httpService.interceptors.request.use(
  (config) => {
    // You can add common headers or perform other modifications here

    // Check if the user is logged in and has a JWT token
    const token = localStorage.getItem('jwtToken'); // Assuming you store the token in localStorage
    // If the token exists, add it to the authorization header
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    // Handle request error
    return Promise.reject(error);
  }
);

// Response interceptor
httpService.interceptors.response.use(
  (response) => {
    // You can do some common processing on the response here
    return response.data;
  },
  (error) => {
    // Handle response error
    return Promise.reject(error);
  }
);

export default httpService;
