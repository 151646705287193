import React from "react";

import banner from '../../assets/image/innerbanner.png';

const HeaderBanner = () => {

  return (<div>
    <img src={banner} alt="" title="inner banner" />
  </div>)
};

export default HeaderBanner;