import React from "react";
import Fruive from '../../../assets/image/fruive.png';
import Travel from '../../../assets/image/travel.png';
import Blood from '../../../assets/image/blood.png';
import bgdfh from '../../../assets/image/bgdfh.png';
import mlmpanel from '../../../assets/image/mlmpanel.png';

function AllPortfolioComponent() {
  return (
    <section>
      <div className="row">
        <div className="col-lg-3 col-sm-4 col-md-4 mb-3">
          <img src={Fruive} alt="" className="fluid img-thumbnail" />
        </div>
        <div className="col-lg-3 col-sm-4 col-md-4 mb-3">
          <img src={Travel} alt="" className="fluid img-thumbnail" />
        </div>
        <div className="col-lg-3 col-sm-4 col-md-4 mb-3">
          <img src={Blood} alt="" className="fluid img-thumbnail" />
        </div>
        <div className="col-lg-3 col-sm-4 col-md-4 mb-3">
          <img src={bgdfh} alt="" className="fluid img-thumbnail" />
        </div>
        <div className="col-lg-3 col-sm-4 col-md-4 mb-3">
          <img src={mlmpanel} alt="" className="fluid img-thumbnail" />
        </div>
      </div>
    </section>
  )
}

export default AllPortfolioComponent;