import './App.css';
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from './Components/Pages/Home';
import About from './Components/Pages/About';
import Service from './Components/Pages/Services';
import ProtfolioComponent from './Components/Pages/Portfolio/ProtfolioComponent';
import OurProcess from './Components/Pages/OurProcess';
import Faq from './Components/Pages/Faq';
import Contact from './Components/Pages/Contact';
import Header from './Components/Shared/Header';
import Footer from './Components/Shared/Footer';
function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Service />} />
        <Route path="/portfolio" element={<ProtfolioComponent />} />
        <Route path="/our-process" element={<OurProcess />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;